//import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import "react-toastify/dist/ReactToastify.css";

// window.$APIPath = 'https://localhost:44306' //global variable
//window.$APIPath = 'http://api.neo7logix.com' //global variable
// window.$APIPath = 'https://devapi.neo7logix.com' //development ap
//window.$APIPath = 'https://neoapi.neo7logix.com';//Aws server neo global variable
//window.$APIPath = 'http://localhost:91';
// window.$APIPath = 'https://qaapi.neo7logix.com';
window.$APIPath = "https://devapi.neo7logix.com/";
window.$FileUrl = 'https://neo7qa.s3.us-east-2.amazonaws.com/';//Global variable for s3 bucket file preview
//window.$FileUrl = 'https://patient-files-bucket.s3.us-east-2.amazonaws.com/'

window.$TotalRecord = 15;
//const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
//const rootElement = document.getElementById('root');------

ReactDOM.render(<App />, document.getElementById('root'));

//ReactDOM.render(
//  <BrowserRouter basename={baseUrl}>
//    <App />
//  </BrowserRouter>,
//  rootElement);

registerServiceWorker();

